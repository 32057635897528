import { atom } from "jotai";

export interface AppBarSticky {
  appBarHeight: number;
  stickyHeight: number;
}

export const appBarStickyAtom = atom<AppBarSticky>({
  appBarHeight: 0,
  stickyHeight: 0,
});

export interface AppBarScrollSticky {
  stickyScrollHeight: number;
}

export const appBarScrollStickyAtom = atom<AppBarScrollSticky>({
  stickyScrollHeight: 0,
});
