import { useResponsive } from "@product/scmp-sdk";
import { useDeepCompareMemo } from "@react-hookz/web";
import type { FunctionComponent } from "react";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { isNativeAdUnit } from "scmp-app/components/advertisement/ad-slots/types";
import { useAppBarStickyHeightValue } from "scmp-app/components/app-bar/hooks";
import { useMutationObserver } from "scmp-app/lib/hooks";

import type { SlotProps } from "./slot";
import type { StyledProps } from "./styles";
import { AdSlotContainer, Container, Label, StyledSlot } from "./styles";

export type Props = {
  breakpoint?: string;
  className?: string;
  customizedAdUnitTargeting?: string;
  fluidDefaultSize?: {
    height?: number;
    width?: number;
  };
  isDisabled?: boolean;
  stickyLabel?: boolean;
  stickySpacing?: number;
  withBackground?: boolean;
  withLabel?: boolean;
  withSticky?: boolean;
} & SlotProps;

export const AdSlot: FunctionComponent<Props> = ({
  breakpoint = "",
  className,
  customizedAdUnitTargeting,
  fluidDefaultSize,
  isDisabled,
  stickyLabel,
  stickySpacing = 0,
  withBackground,
  withLabel,
  withSticky,
  ...props
}) => {
  const isBreakpoint = useResponsive(breakpoint, false);

  const placeholderSizes = useDeepCompareMemo(() => {
    const computePlaceholderSize = (variant: "max" | "min") => {
      let sizes = props.sizes;
      if (!Array.isArray(sizes)) sizes = [sizes];

      const size: StyledProps["$minContainerSize"] = {
        $blockSize: undefined,
        $inlineSize: undefined,
      };

      const compareSize = (width?: number, height?: number) => {
        const parsedWidth = Number.parseInt(`${width ?? 0}`);
        const parsedHeight = Number.parseInt(`${height ?? 0}`);

        const compareFunction = variant === "max" ? Math.max : Math.min;
        size.$blockSize =
          size.$blockSize === undefined
            ? parsedHeight
            : compareFunction(parsedHeight, size.$blockSize);
        size.$inlineSize =
          size.$inlineSize === undefined
            ? parsedWidth
            : compareFunction(parsedWidth, size.$inlineSize);
      };

      for (const generalSize of sizes) {
        if (
          generalSize === "fluid" ||
          (Array.isArray(generalSize) && generalSize?.[0] === "fluid")
        ) {
          if (!fluidDefaultSize) continue;
          const { height, width } = fluidDefaultSize;
          compareSize(width, height);
        } else if (Array.isArray(generalSize)) {
          const [width, height] = generalSize;
          compareSize(width, height);
        }
      }

      return size;
    };

    return {
      max: computePlaceholderSize("max"),
      min: computePlaceholderSize("min"),
    };
  }, [props.sizes]);

  const { appBarStickyHeight } = useAppBarStickyHeightValue();

  const [currentPosition, setCurrentPosition] = useState(withSticky ? "sticky" : "");
  const { captureTargetElement, element } = useMutationObserver(
    () => {
      if (!element) return;
      const adSlotStyle = getComputedStyle(element);
      if (adSlotStyle.position !== currentPosition) setCurrentPosition(adSlotStyle.position);
    },
    {
      attributeFilter: ["style"],
      attributes: true,
    },
  );

  const styledProps: StyledProps = {
    $breakpoint: breakpoint,
    $currentPosition: currentPosition,
    $minContainerSize: withSticky ? placeholderSizes.min : placeholderSizes.max,
    $stickyStackedHeight: appBarStickyHeight + stickySpacing,
    $withAutoContainerSize: isNativeAdUnit(props.adUnit),
    $withBackground: withBackground,
    $withLabel: withLabel,
    $withSticky: withSticky ? { $stickyLabel: stickyLabel, ...placeholderSizes.max } : undefined,
  };

  return (
    <ErrorBoundary fallback={<></>}>
      <Container {...styledProps} className={className}>
        <Label>Advertisement</Label>
        <AdSlotContainer>
          {isBreakpoint && !isDisabled && (
            <StyledSlot
              customizedAdUnitTargeting={customizedAdUnitTargeting}
              mutationObserveElement={captureTargetElement}
              {...props}
            />
          )}
        </AdSlotContainer>
      </Container>
    </ErrorBoundary>
  );
};

AdSlot.displayName = "AdSlot";
