import { useSyncedRef } from "@react-hookz/web";
import { useCallback, useEffect, useState } from "react";

export const useMutationObserver = (callback: MutationCallback, options: MutationObserverInit) => {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const latestCallback = useSyncedRef(callback);
  const latestOptions = useSyncedRef(options);

  const captureTargetElement = useCallback((element: HTMLElement | null) => {
    setElement(element);
  }, []);

  useEffect(() => {
    if (!element) return;
    const observer = new MutationObserver(latestCallback.current);
    observer.observe(element, latestOptions.current);
    return () => {
      observer.disconnect();
    };
  }, [element, latestCallback, latestOptions]);

  return { captureTargetElement, element };
};
