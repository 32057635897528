import type { RenderedSlotInfo } from "scmp-app/components/advertisement/google-publisher-tag";

export const getSizeFromRenderedInfo = (slotInfo: null | RenderedSlotInfo) => {
  if (Array.isArray(slotInfo?.size))
    return {
      height: slotInfo?.size?.[1] ?? 0,
      width: slotInfo?.size?.[0] ?? 0,
    };
  return {
    height: 0,
    width: 0,
  };
};

export const getClassNameFromSize = ({ height, width }: { height: number; width: number }) =>
  `ad-with-size-${width}x${height}`;
