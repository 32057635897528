import { useEventListener, useSyncedRef } from "@react-hookz/web";
import { useInterval } from "ahooks";
import { addMinutes } from "date-fns";
import { useAtomValue, useSetAtom } from "jotai";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useIdle } from "react-use";
import { v1 as uuidV1 } from "uuid";

import { useRenderSlotInfo } from "scmp-app/components/advertisement/google-publisher-tag/hooks";
import { useAdvertisementConfig } from "scmp-app/components/advertisement/hooks";
import { getGoogleAnalyticsClientId } from "scmp-app/components/tracking/google-analytics/apis";
import { useLighthouseMapValue } from "scmp-app/components/tracking/mirror";
import { appAtom } from "scmp-app/lib/app/atoms";
import { useParsedCookie } from "scmp-app/lib/hooks";
import { getWindow } from "scmp-app/lib/utils";

import { trackViewabilityEvents } from "./apis";
import { viewabilityTrackingAtom } from "./atoms";
import type {
  ViewabilityEvent,
  ViewabilityEventGeneralInfo,
  ViewabilityEventPayload,
} from "./types";

export const useAdvertisementViewabilitySetup = () => {
  const {
    config: { viewabilityTracking },
  } = useAdvertisementConfig();
  const { navigatorInfo } = useAtomValue(appAtom);
  const shouldEnable = useShouldEnableTrackViewability();
  // Send requests in batch
  // eslint-disable-next-line react/hook-use-state
  const [, setEventPayloads] = useState<ViewabilityEventPayload[]>([]);
  const handler = useCallback(() => {
    setEventPayloads(currentEvents => {
      void trackViewabilityEvents(currentEvents);
      return [];
    });
  }, []);

  useInterval(handler, viewabilityTracking.workerIntervalMs);
  useEventListener(getWindow(), "beforeunload", handler);

  // Add track event to queue with latest information
  const { sessionUuid } = useSessionUuid();
  const { pageUuid } = usePageUuid();

  const { value: lighthouseMap } = useLighthouseMapValue();

  const trackViewabilityEvent = useCallback(
    async (event: ViewabilityEvent) => {
      if (!shouldEnable) return;

      const info: ViewabilityEventGeneralInfo = {
        audience_id: (lighthouseMap?.["lh2_audience"] ??
          lighthouseMap?.["lh2_audience_uat"] ??
          []) as string[],
        device_category: isMobile ? "mobile web" : "desktop",
        domain: window.location.hostname,
        ga_client_id: (await getGoogleAnalyticsClientId()) ?? "",
        is_bot: navigatorInfo?.result?.isBot ? "Yes" : "No",
        page_uuid: pageUuid,
        session_uuid: sessionUuid,
        url: window.location.pathname,
        user_agent: window.window.navigator.userAgent,
      };

      setEventPayloads(events => [
        ...events,
        {
          ...event,
          ...info,
          timestamp: Date.now(),
        },
      ]);
    },
    [shouldEnable, lighthouseMap, navigatorInfo?.result?.isBot, pageUuid, sessionUuid],
  );
  const latestTrackViewabilityEvent = useSyncedRef(trackViewabilityEvent);

  useEffect(() => {
    const handler = (event: googletag.events.Event) => {
      void latestTrackViewabilityEvent.current({
        ad_unit: event.slot.getAdUnitPath(),
        event_type: "Viewable impressions",
      });
    };

    googletag.cmd.push(() => {
      googletag.pubads().addEventListener("impressionViewable", handler);
    });

    return () => {
      googletag.cmd.push(() => {
        googletag.pubads().removeEventListener("impressionViewable", handler);
      });
    };
  }, [latestTrackViewabilityEvent]);

  useEffect(() => {
    const handler = (event: googletag.events.SlotRenderEndedEvent) => {
      void latestTrackViewabilityEvent.current({
        ad_unit: event.slot.getAdUnitPath(),
        creative_id: event.creativeId,
        event_type: "Measurable impressions",
        line_item_id: event.lineItemId,
        order_id: event.campaignId,
      });
    };

    googletag.cmd.push(() => {
      googletag.pubads().addEventListener("slotRenderEnded", handler);
    });

    return () => {
      googletag.cmd.push(() => {
        googletag.pubads().removeEventListener("slotRenderEnded", handler);
      });
    };
  }, [latestTrackViewabilityEvent]);

  const setViewabilityTracking = useSetAtom(viewabilityTrackingAtom);
  useEffect(() => {
    setViewabilityTracking({ trackViewabilityEvent });
  }, [setViewabilityTracking, trackViewabilityEvent]);
};

const useSessionUuid = () => {
  const cookieName = "session_uuid";
  const { value } = useParsedCookie(cookieName, (raw: string) => raw, {
    initializeWithValue: true,
  });

  if (!value) {
    const newValue = `s-${uuidV1()}`;
    // Not using update from useParsedCookie is because of preventing set state on render stage
    Cookies.set(cookieName, newValue, {
      expires: addMinutes(Date.now(), 30),
    });
    return { sessionUuid: newValue };
  }

  return { sessionUuid: value };
};

const usePageUuid = () => {
  const newPageUuid = () => `p-${uuidV1()}`;
  const [pageUuid, setPageUuid] = useState(newPageUuid());

  const router = useRouter();
  useEffect(() => {
    const handler = () => {
      setPageUuid(newPageUuid());
    };
    router.events.on("routeChangeComplete", handler);

    return () => {
      router.events.off("routeChangeComplete", handler);
    };
  }, [router.events]);

  return { pageUuid };
};

export const useTrackViewability = (
  slot: googletag.Slot | null,
  intersection: IntersectionObserverEntry | null,
) => {
  const {
    config: { viewabilityTracking },
  } = useAdvertisementConfig();
  const shouldEnable = useShouldEnableTrackViewability();

  const [totalAdDwellSecond, setTotalAdDwellSecond] = useState(0);
  const [totalADInteractiveSecond, setTotalADInteractiveSecond] = useState(0);
  useEffect(() => {
    setTotalAdDwellSecond(0);
    setTotalADInteractiveSecond(0);
  }, [slot, intersection?.isIntersecting]);

  const { trackViewabilityEvent } = useAtomValue(viewabilityTrackingAtom);
  const isIdle = useIdle(15_000, true);
  const renderedInfo = useRenderSlotInfo(slot);

  useInterval(
    () => {
      if (!slot) return;
      if (!renderedInfo) return;

      const elapsedSecond = viewabilityTracking.eventIntervalMs / 1000;
      const slotInfo = {
        ad_unit: slot.getAdUnitPath(),
        creative_id: renderedInfo.creativeId,
        line_item_id: renderedInfo.lineItemId,
        order_id: renderedInfo.campaignId,
      };

      const nextTotalAdDwellSecond = totalAdDwellSecond + elapsedSecond;
      trackViewabilityEvent({
        ...slotInfo,
        event_type: "Ad dwell time",
        second: nextTotalAdDwellSecond,
      });
      setTotalAdDwellSecond(nextTotalAdDwellSecond);

      if (!isIdle) {
        const nextTotalADInteractiveSecond = totalADInteractiveSecond + elapsedSecond;
        trackViewabilityEvent({
          event_type: "Ad interaction time",
          ...slotInfo,
          second: nextTotalADInteractiveSecond,
        });
        setTotalADInteractiveSecond(nextTotalADInteractiveSecond);
      }
    },
    shouldEnable ? viewabilityTracking.eventIntervalMs : undefined,
  );
};

export const useShouldEnableTrackViewability = () => {
  const {
    config: { viewabilityTracking },
  } = useAdvertisementConfig();

  const shouldEnable = useMemo(() => {
    if (!viewabilityTracking.enable) return false;
    if (viewabilityTracking.testMode.enable) {
      return viewabilityTracking.testMode.targetUrlPatterns.some(pattern => {
        const regex = new RegExp(pattern.replaceAll("*", ".+?"), "i");
        return regex.test(location.pathname);
      });
    }
    return true;
  }, [viewabilityTracking]);

  return shouldEnable;
};
