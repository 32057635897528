import { atom } from "jotai";

import type { ViewabilityEvent } from "./types";

export interface ViewabilityTracking {
  trackViewabilityEvent(this: void, items: ViewabilityEvent): void;
}

export const viewabilityTrackingAtom = atom<ViewabilityTracking>({
  trackViewabilityEvent: () => {},
});
