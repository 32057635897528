export const fetchBids = (slots: apstag.Slot[] | googletag.Slot[]) => {
  const result = new Promise<apstag.Bid[]>(resolve => {
    apstag.fetchBids(
      {
        slots,
      },
      bids => {
        apstag.setDisplayBids();
        resolve(bids);
      },
    );
  });

  return { result };
};
