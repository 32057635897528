import { useDeepCompareEffect, useSyncedRef } from "@react-hookz/web";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";

import { getGoogleAnalyticsClientId } from "scmp-app/components/tracking/google-analytics/apis";
import { accountAtom } from "scmp-app/lib/account";

export const useDefineSlot = (
  adUnitPath: string,
  sizes: googletag.GeneralSize,
  targeting: Record<string, string | string[]>,
  options?: {
    divId?: string;
    sizeMappingBuilder?: (builder: googletag.SizeMappingBuilder) => googletag.SizeMappingArray;
  },
) => {
  const [slot, setSlot] = useState<googletag.Slot | null>(null);

  useDeepCompareEffect(() => {
    let slot: OrUndefined<googletag.Slot>;
    googletag.cmd.push(() => {
      slot = options?.divId
        ? googletag.defineSlot(adUnitPath, sizes, options.divId)?.addService(googletag.pubads())
        : googletag.defineSlot(adUnitPath, sizes)?.addService(googletag.pubads());
      if (!slot) return;

      if (options?.sizeMappingBuilder) {
        slot.defineSizeMapping(options.sizeMappingBuilder(googletag.sizeMapping()));
      }
      Object.entries(targeting).forEach(([key, value]) => {
        slot?.setTargeting(key, value);
      });
      setSlot(slot);
    });

    return () => {
      googletag.cmd.push(() => {
        if (!slot) return;
        googletag.destroySlots([slot]);
        setSlot(null);
      });
    };
  }, [adUnitPath, sizes, targeting, options?.divId, options?.sizeMappingBuilder]);

  return slot;
};

export const useRenderSlotInfo = (slot: googletag.Slot | null) => {
  const [state, setState] = useState<null | RenderedSlotInfo>(null);
  const latestSlot = useSyncedRef(slot);

  useEffect(() => {
    const listener: (event: googletag.events.SlotRenderEndedEvent) => void = event => {
      if (!latestSlot.current) {
        setState(null);
        return;
      }
      if (event.slot !== latestSlot.current) return;
      const { slot: _omitted, ...remaining } = event;
      setState({ ...remaining });
    };

    googletag.cmd.push(() => {
      googletag.pubads().addEventListener("slotRenderEnded", listener);
    });

    return () => {
      googletag.cmd.push(() => {
        googletag.pubads().removeEventListener("slotRenderEnded", listener);
      });
    };
  }, [latestSlot]);

  return state;
};

export type RenderedSlotInfo = Omit<googletag.events.SlotRenderEndedEvent, "slot">;

export const usePageTargeting = () => {
  const { user } = useAtomValue(accountAtom);

  useEffect(() => {
    const searchParameters = new URL(location.href).searchParams;
    const run = async () => {
      const gaClientId = await getGoogleAnalyticsClientId();

      googletag.cmd.push(() => {
        const publisherProvidedId = user?.uuid ?? gaClientId;
        if (publisherProvidedId) {
          googletag.pubads().setPublisherProvidedId(publisherProvidedId);
        }
        if (user?.uuid) {
          googletag.pubads().setTargeting("uuid", user.uuid);
        }
        if (gaClientId) {
          googletag.pubads().setTargeting("ga_id", gaClientId);
        }

        const cid = searchParameters.get("cid");
        if (cid) {
          googletag.pubads().setTargeting("cid", cid);
        }
      });
    };

    void run();
  }, [user?.uuid]);
};
