import { useCallback } from "react";

import { fetchBids } from "./apis";

export const useFetchBids = (slot: googletag.Slot | null) => {
  const handleFetchBids = useCallback(async () => {
    if (!slot) return;
    const isValidApsAdSlot = (slot: googletag.Slot) => slot.getSizes().some(s => s !== "fluid");
    if (isValidApsAdSlot(slot)) {
      const { result } = fetchBids([slot]);
      await result;
    }
  }, [slot]);

  return {
    handleFetchBids,
  };
};
