import { useAtomValue } from "jotai";

import { appBarStickyAtom } from "./atoms";

export const useAppBarStickyHeightValue = () => {
  const appBarSticky = useAtomValue(appBarStickyAtom);
  return {
    /**
     * This value of the app bar height
     */
    appBarHeight: appBarSticky.appBarHeight,
    /**
     * This value is the actual height of the app bar when it's in position sticky
     */
    appBarStickyHeight: appBarSticky.stickyHeight,
  };
};
